$text-color: #121212;

%item-shared {
    display: block;
    line-height: 16px;
    min-height: 40px;
    padding: 12px;
    position: relative;
    text-decoration: none;
    text-transform: none;
    vertical-align: middle;
    white-space: nowrap;
}

%search-icon-before {
    fill: #3b4143;
    color: #3b4143;
    left: 0;
    position: absolute;
    top: 3px;
}

%home-find-locations-before {
    content: url('data:image/svg+xml,%0A%3Csvg height="20" width="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill="currentColor"%3E%3Cpath d="M508.5 468.9L387.1 347.5c-2.3-2.3-5.3-3.5-8.5-3.5h-13.2c31.5-36.5 50.6-84 50.6-136C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c52 0 99.5-19.1 136-50.6v13.2c0 3.2 1.3 6.2 3.5 8.5l121.4 121.4c4.7 4.7 12.3 4.7 17 0l22.6-22.6c4.7-4.7 4.7-12.3 0-17zM208 368c-88.4 0-160-71.6-160-160S119.6 48 208 48s160 71.6 160 160-71.6 160-160 160z"/%3E%3C/svg%3E');
    height: 20px;
    width: 20px;
}

%home-find-bedrooms {
    content: url('data:image/svg+xml,%0A%3Csvg height="19" width="23" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"%3E%3Cpath d="M176 288C220.1 288 256 252.1 256 208S220.1 128 176 128S96 163.9 96 208S131.9 288 176 288zM544 128H304C295.2 128 288 135.2 288 144V320H64V48C64 39.16 56.84 32 48 32h-32C7.163 32 0 39.16 0 48v416C0 472.8 7.163 480 16 480h32C56.84 480 64 472.8 64 464V416h512v48c0 8.837 7.163 16 16 16h32c8.837 0 16-7.163 16-16V224C640 170.1 597 128 544 128z"/%3E%3C/svg%3E');
    height: 19px;
    width: 23px;
}

.home-find {
    position: relative;
    width: 100%;
    color: $text-color;
    border: 1px solid #d1d5db;
    border-radius: 5px;
    padding-left: 35px;
    font-size: 18px;
    min-height: 50px;

    &:before {
        @extend %search-icon-before;
    }

    &[name='home-find-locations']:before {
        @extend %home-find-locations-before;
    }

    &[name='home-find-bedrooms']:before {
        @extend %home-find-bedrooms;
    }
}

.choices {
    color: $text-color;
    border: 1px solid #d1d5db;
    border-radius: 5px;

    &__inner {
        min-height: 50px;
        border: none;
        background-color: #fff;

        .choices {
            &__item {
                position: relative;
                padding-left: 35px;
                font-size: 18px;

                &:before {
                    @extend %search-icon-before;
                }

                @at-root .home-find-locations .choices #{&}:before {
                    @extend %home-find-locations-before;
                }

                @at-root .home-find-bedrooms .choices #{&}:before {
                    @extend %home-find-bedrooms;
                }

                &:not(.choices__placeholder) {
                    padding-left: unset;

                    &:before {
                        display: none;
                    }
                }
            }

            &__placeholder {
                opacity: 1;
            }
        }
    }

    &__list {
        font-size: 14px;

        &[aria-expanded] {
            background-color: #fff;
            border: 1px solid #d1d5db;
            border-bottom-left-radius: 5px;
            border-bottom-right-radius: 5px;

            input[type='search'] {
                box-shadow: unset;
                border-color: unset;
                outline-offset: unset;
                border-bottom: 1px solid #d1d5db;
            }
        }

        &[role='listbox'] {
            .choices {
                &__placeholder {
                    @at-root .home-find-locations #{&} {
                        display: none;
                    }
                }

                &__item {
                    @extend %item-shared;
                    cursor: pointer;

                    &.is-highlighted {
                        background-color: #00a2e1;
                        color: #fff;

                        &:after {
                            color: #fff;
                            opacity: 1;
                        }
                    }

                    &.is-selected {
                        background: #f49131;
                        color: #fff;
                    }
                }

                &__group {
                    @extend %item-shared;
                    background: #ededed;
                    color: #a6a6a6;

                    .choices__heading {
                        font-weight: unset;
                        font-size: unset;
                        padding: unset;
                        border-bottom: unset;
                        color: unset;
                    }
                }
            }
        }
    }
}

.hero-search-refresh {
    .choices {
        border: none;
        border-radius: 0;
    }

    .choices[data-type*=select-one]::after {
        display: none;
    }

    .select-city::before,
    .select-bedrooms::before {
        display: block;
        position: absolute;
        left: 12px;
        top: 12px;
        height: 24px;
        width: 24px;
        z-index: 5;
    }

    .select-city::before {
        z-index: 100;
        content: url('data:image/svg+xml,<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M5.25 21.75H18.75" stroke="%2300A2E1" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M12 12.75C13.6569 12.75 15 11.4069 15 9.75C15 8.09315 13.6569 6.75 12 6.75C10.3431 6.75 9 8.09315 9 9.75C9 11.4069 10.3431 12.75 12 12.75Z" stroke="%2300A2E1" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M19.5 9.75C19.5 16.5 12 21.75 12 21.75C12 21.75 4.5 16.5 4.5 9.75C4.5 7.76088 5.29018 5.85322 6.6967 4.4467C8.10322 3.04018 10.0109 2.25 12 2.25C13.9891 2.25 15.8968 3.04018 17.3033 4.4467C18.7098 5.85322 19.5 7.76088 19.5 9.75Z" stroke="%2300A2E1" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>');
    }

    .select-bedrooms::before {
        content: url('data:image/svg+xml,<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M10.5 15.75V7.5H20.25C21.0456 7.5 21.8087 7.81607 22.3713 8.37868C22.9339 8.94129 23.25 9.70435 23.25 10.5V15.75" stroke="%2300A2E1" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M2.25 19.5V4.5" stroke="%2300A2E1" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M2.25 15.75H23.25V19.5" stroke="%2300A2E1" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M10.5 7.5H2.25" stroke="%2300A2E1" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>');
    }

    .select-bedrooms .choices[data-type*=select-one]::after {
        content: url('data:image/svg+xml,<svg width="18" height="10" viewBox="0 0 18 10" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M16.5 1L9 8.5L1.5 1" stroke="%233B4143" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>');
        display: block;
        margin-top: 0;
        position: absolute;
        right: 18px;
        top: 14px;
        height: 20px;
        width: 18px;
        border: none;
        transition: 0.2s ease-in-out;
    }

    .select-bedrooms .choices[data-type*=select-one].is-open::after {
        margin-top: 0;
        transform: rotate(180deg);
        transition: 0.2s ease-in-out;
    }

    .select-city .choices:hover,
    .select-city .choices__inner .choices__item {
        cursor: text;
    }

    .choices__inner {
        display: flex;
        align-items: center;
        border-radius: 9999px;
        transition: 0.2s ease-in-out;
    }

    .select-city .choices__inner {
        @media (min-width: 1024px) {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        }
    }

    .select-bedrooms .choices__inner {
        @media (min-width: 1024px) {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }
    }

    .choices__inner .choices__item {
        color: #5C6A75;
        font-size: 14px !important;
        font-weight: 400;

        @media (min-width: 360px) {
            font-size: 16px !important;
        }
    }

    .choices__list--dropdown, .choices__list[aria-expanded] {
        z-index: 10;
        top: calc(100% + 12px);
        border-radius: 16px;
    }


    .choices__inner .choices__item,
    .choices__inner .choices__item:not(.choices__placeholder) {
        padding-left: 36px;
    }

    .select-city.text-red .choices__inner .choices__item {
        color: #E02424 !important;
    }

    .choices__list[role=listbox]::-webkit-scrollbar {
        display: none; /* chrome, Safari and Opera */
    }

    .no-scrollbar {
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
    }

    .choices__list[role=listbox] .choices__item {
        opacity: 1;
    }

    .choices__list[role=listbox] .choices__item.is-selected,
    .choices__list[role=listbox] .choices__item.is-highlighted {
        background-color: #e5e5e5;
        color: black;
    }

    .choices__list[role=listbox] .choices__item.is-highlighted:after {
        display: none;
    }

    .choices__list[role=listbox] .choices__group, .choices__list[role=listbox] .choices__item {
        padding: 16px;
        font-weight: 600;
    }

    .choices__list[role=listbox] .choices__group {
        color: #3B4143;
        font-weight: bold;
    }

    .choices[data-type*=select-one] .choices__input {
        border-radius: 16px;
        border-bottom: none;
        padding: 14px;
    }

    .select-city .choices__list--dropdown {
        position: absolute;
        top: 0;
    }

    .select-city input[type=search] {
        margin-top: -2px;
        margin-bottom: 4px;
        padding-left: 48px !important;
        font-size: 16px;
    }

    .select-city #choices--home-find-locations-item-choice-1 {
        display: none;
    }

    .select-city .choices__group {
        display: none;
    }

    .select-city .choices__list[aria-expanded] {
        border: none !important;
        background-color: transparent !important;
    }

    .select-city .choices__list--single {
        background-color: white;
        border-radius: 16px;
    }

    .select-city .choices__list[role="listbox"] {
        margin-top: 10px;
        background-color: white;
        border-radius: 16px;
    }
}
